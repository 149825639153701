<template>
  <div
    v-if="show"
    ref="confimration_modal"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__confirm"
    id="confirmModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="w-100 h-100 d-flex">
      <div class="modal__dialog" role="document">
        <div class="modal__header mb-3">
          <div class="d-flex align-items-center justify-content-between">
            <span class="modal__header__title text--capital">
              Adjust {{ type }}
            </span>
            <a
              class="modal__close modal__close--white d-flex align-items-center justify-content-between"
              @click="closeAdjustedTransaction"
            >
              <span class="text--capital text--regular"> close </span>
              <ion-icon name="close-outline"></ion-icon>
            </a>
          </div>
          <span class="modal__header__subtitle text--capital"> </span>
        </div>
        <div class="modal__content">
          <div class="modal__body">
            <div class="account_item_head">
              <p class="account_item_head_text text--white">general</p>
            </div>
            <div class="row no-gutters account_item align-items-center">
              <div class="col-6">
                <span
                  class="text--black text--opaq2 text--small text--bold account_item_title"
                >
                  Fee Amount
                </span>
                <span
                  class="text--black text--opaq2 text--small text--bold account_item_title"
                >
                  Type
                </span>
              </div>
              <div class="col-6">
                <span class="text--black text--regular account_item_subtitle">
                  Fee Date
                </span>
                <span class="text--black text--regular account_item_subtitle">
                  {{ transactionValue.valuedDate | moment }}
                </span>
              </div>
            </div>
            <div class="row no-gutters account_item align-items-center mt-5">
              <form>
                <div class="mb5">
                  <div class="form__item">
                    <label class="form__label text--regular text--black"
                      >Adjustment Reason</label
                    >
                    <textarea
                      cols="5"
                      rows="5"
                      class="input form__input"
                      placeholder="Enter comment"
                      v-model="note"
                    ></textarea>
                  </div>
                </div>
                <div class="form__item">
                  <div class="form__action">
                    <button
                      data-dismiss="#confirmModal"
                      class="button form__button form__button--lg save-changes"
                      v-if="loading"
                    >
                      <div
                        class="spinner-border texxt-light"
                        role="status"
                      ></div>
                    </button>
                    <button
                      data-dismiss="#addCommentModal"
                      type="button"
                      class="button form__button form__button--lg save-changes"
                      v-else
                      @click.prevent="adjustFee"
                    >
                      Adjust {{ type }}
                    </button>
                    <a
                      role="button"
                      class="modal__close form__action__text form__action__text--danger text--capital ml6"
                      @click="closeAdjustedTransaction"
                    >
                      cancel
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ApiService from "@/core/services/api.service";

export default {
  name: "AdjustTransaction",
  props: {
    show: Boolean,
    close: Function,
    accountDetails: String,
    transactionValue: [Array, Object],
    savingsAccountKey: String,
  },
  computed: {
    type() {
      return this.transactionValue.transactionType;
    },
  },
  data() {
    return {
      loading: false,
      note: "",
    };
  },
  methods: {
    getTransactions() {
      ApiService.get(
        `Transactions/GetTransactions/${this.accountDetails}`
      ).then((response) => {
        this.transactionValue = response.data.data;
      });
    },
    closeAdjustedTransaction() {
      this.note = "";
      this.$emit("close");
    },
    adjustFee() {
      this.loading = true;
      ApiService.put(
        `Transactions/adjusttransaction/${this.accountDetails}/${this.savingsAccountKey}`,
        this.note
      )
        .then((response) => {
          if (response.data.status !== false) {
            this.loading = false;
            this.$emit("adjustfee-success", response.data.message);
            this.note = "";
          } else {
            this.loading = false;
            this.$emit("adjustfee-error", response.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("adjustfee-error", error.response.data.message);
        });
    },
  },
  mounted() {
    this.getTransactions();
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
};
</script>
<style scoped>
form {
  width: 100%;
}
</style>
